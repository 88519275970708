import React, { useEffect } from "react";
import "../../../node_modules/swiper/swiper.min.css";
import striptags from "striptags";
import Image from "next/image";
import handleViewport from "react-in-viewport";

import technologies_logo_3 from "../../../public/assets/images/technologies-logo-3.svg";
import technologies_logo_4 from "../../../public/assets/images/technologies-logo-4.svg";
import technologies_logo_6 from "../../../public/assets/images/technologies-logo-6.svg";

const BehindService = (props) => {
  const { forwardedRef, inViewport } = props;
  const behindServiceData = props.behindServiceData;

  const titleWithoutPTag = behindServiceData?.value?.title;

  useEffect(() => {
    console.clear();
  }, []);

  return (
    <>
      <section
        ref={forwardedRef}
        style={{ minHeight: "500px" }}
        className="behind-services-section"
      >
        {inViewport && (
          <div className="behind-services-inner">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="section-heading">
                    <span
                      itemProp="heading"
                      dangerouslySetInnerHTML={{
                        __html: titleWithoutPTag,
                      }}
                    ></span>
                    <p
                      itemProp="description"
                      dangerouslySetInnerHTML={{
                        __html: behindServiceData?.value?.description,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="technologies-logos">
                    <div className="row">
                      <div className="col-lg-6 col-md-4 technologies-left-logo">
                        <div className="technologies-left">
                          <Image
                            src={behindServiceData?.value?.image?.url}
                            alt={behindServiceData?.value?.image?.alt_text ?? 'quixom'}
                            width={500}
                            height={500}
                            priority
                            loading="eager"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-8">
                        <div className="technologies-logo-card">
                          <ul className="technologies-logo-row">
                            <li className="tech-col-1 tech-n-col-1">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(0, 4)
                                    ?.map((val, i) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                          key={`${i}_k`}
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                          </ul>
                          <ul className="technologies-logo-row">
                            <li className="tech-col-2 tech-n-col-2">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(1, 5)
                                    ?.map((val, index) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          key={index}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                            <li className="tech-col-3 tech-n-col-1">
                              <div className="technologies-image">
                                <Image
                                  height={100}
                                  width={100}
                                  src={technologies_logo_3}
                                  alt="technologie"
                                  loading="eager"
                                />
                                <Image
                                  height={100}
                                  width={100}
                                  src={technologies_logo_6}
                                  alt="technologie"
                                  loading="eager"
                                />
                                <Image
                                  height={100}
                                  width={100}
                                  src={technologies_logo_3}
                                  alt="technologie"
                                  loading="eager"
                                />
                                <Image
                                  height={100}
                                  width={100}
                                  src={technologies_logo_4}
                                  alt="technologie"
                                  loading="eager"
                                />
                              </div>
                            </li>
                          </ul>
                          <ul className="technologies-logo-row">
                            <li className="tech-col-4 tech-n-col-3">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(2, 6)
                                    ?.map((val, index) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          key={index}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                            <li className="tech-col-5 tech-n-col-2">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(3, 7)
                                    ?.map((val, index) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          key={index}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                            <li className="tech-col-6 tech-n-col-1">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(4, 8)
                                    ?.map((val, index) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          key={index}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                          </ul>
                          <ul className="technologies-logo-row">
                            <li className="tech-col-1 tech-n-col-4">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(5, 9)
                                    ?.map((val, index) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          key={index}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                            <li className="tech-col-2 tech-n-col-3">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(6, 10)
                                    ?.map((val, index) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          key={index}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                            <li className="tech-col-3 tech-n-col-2">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(7, 11)
                                    ?.map((val, index) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          key={index}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                            <li className="tech-col-4 tech-n-col-1">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(8, 12)
                                    ?.map((val, index) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          key={index}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                          </ul>
                          <ul className="technologies-logo-row">
                            <li className="tech-col-5 tech-n-col-5">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(9, 13)
                                    ?.map((val, index) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          key={index}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                            <li className="tech-col-6 tech-n-col-4">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(10, 14)
                                    ?.map((val, index) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          key={index}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                            <li className="tech-col-1 tech-n-col-3">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(11, 15)
                                    ?.map((val, index) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          key={index}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                            <li className="tech-col-2 tech-n-col-2">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(2, 6)
                                    ?.map((val, index) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          key={index}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                            <li className="tech-col-3 tech-n-col-1">
                              <div className="technologies-image">
                                {behindServiceData?.value?.images &&
                                  behindServiceData?.value?.images
                                    ?.slice(4, 8)
                                    ?.map((val, index) => {
                                      return (
                                        <Image
                                          height={100}
                                          width={100}
                                          key={index}
                                          src={val?.url}
                                          alt={val?.alt_text ?? 'technologies'}
                                          loading="eager"
                                        />
                                      );
                                    })}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

const BehindServiceViewportBlock = handleViewport(BehindService);

export default BehindServiceViewportBlock;
// export default BehindService;
